module controllers {
    export module master {

        interface IGoodsReceiptNoteListCtrlScope extends ng.IScope {


        }

        interface IGoodsReceiptNoteListParams extends ng.ui.IStateParamsService {
            FilterList: interfaces.applicationcore.IKeyValue[];
            SearchList: interfaces.applicationcore.IKeyValue[];
        }

        export class goodsReceiptNoteListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'goodsReceiptNoteService',
                '$state',
                '$stateParams',
                '$transitions',
                'statusService',
                'classificationValueService',
                'menuService'
            ];

            goodsReceiptNoteSearch: interfaces.master.IGoodsReceiptNoteSearch;

            IsLoading: boolean = false;
            ShowGrid: boolean = false;
            vatId: number = 0;

            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.master.IGoodsReceiptNotesDisplay>;
            searchAccordian: boolean = false;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string;

            grnId: number = 0;

            sortName: string;
            sortDirection: string;
       
            ownerEntityId: number;

            apiList: uiGrid.IGridApi;
            selected: uiGrid.IGridRow;

            items: string[];
            entity: string;
            currentEntity: interfaces.applicationcore.IEntity;

            selectedRowsArray: Array<interfaces.master.IGoodsReceiptNotesDisplay> = [];

            filterNames: string[] = ["EntityCode",
                "GRNNumber",
                "GRNDate",
                "IsIntegrated",                
                "IsFullGRN"];

            filterList: interfaces.applicationcore.IKeyValue[] = [];

            hasCreateGRNRight: boolean = false;

            constructor(private $scope: IGoodsReceiptNoteListCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private goodsReceiptNoteService: interfaces.master.IGoodsReceiptNoteService,
                private $state: ng.ui.IStateService,
                private $stateParams: IGoodsReceiptNoteListParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private menuService: interfaces.applicationcore.IMenuService
            ) {
                this.loadControls();
            }

            loadgoodsReceiptNotes() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'goodsReceiptNote'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.goodsReceiptNoteSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.goodsReceiptNoteService.getList().query(params, (data) => {
                            this.IsLoading = false;
                            this.gvwList.data = data;
                            this.selectedRowsArray = [];

                            if (data[0]) {
                                this.gvwList.totalItems = data[0].NumRecs;
                            } else {
                                this.gvwList.totalItems = 0;
                            }
                            this.$timeout(() => {
                                if (this.apiList) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });

                        }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });

            }


            getCreateGRNRight() {
                this.hasCreateGRNRight = false;
                
                return this.menuService.getGTSConnectMenuItem(640).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.hasCreateGRNRight = true;
                    }
                }).$promise;
            }



            loadControls() {
                let controlPromises = [
                    this.getCurrentEntity(),
                    this.getCreateGRNRight()
                ]

                this.$q.all(controlPromises).then((data) => {

                    this.ShowGrid = true;
                    this.DoSearch();
                });

            }


            search_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            showAll_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;
                //this.goodsReceiptNoteSearch 
                this.filterList = [];

                if (this.apiList !== undefined) {
                    this.apiList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            DoSearch() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.goodsReceiptNoteSearch, (n, key) => {

                    if (n) {
                        if (n["Id"]) {

                            searchObject.filters.push({ Name: key, Value: n.Id });

                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.apiList) {
                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                if (this.currentEntity)
                    this.entity = this.currentEntity.Code;

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber,
                }

                this.goodsReceiptNoteService.getList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = data;
                    this.selectedRowsArray = [];

                    if (data[0]) {
                        this.gvwList.totalItems = data[0].NumRecs;
                    } else {
                        this.gvwList.totalItems = 0;
                    }


                    this.$timeout(() => {
                        if (this.apiList) {
                            this.getFilters();
                            this.setFilters();
                        }
                    });


                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }




            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }



            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }





            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiList.grid.getColumn(name).filters[0].term || this.apiList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.apiList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            ListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ListShortChange(gridApi) {
                this.DoSearch();
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if (!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if (keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }


            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.apiList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiList.core.on.rowsRendered(this.$scope, () => {

                    for (let selectedRow of this.selectedRowsArray) {

                        for (var row of this.gvwList.data) {

                            if (selectedRow.Id === row.Id) {
                                this.apiList.selection.selectRow(row);
                            }
                        }
                    }
                });


                this.$timeout(() => {
                    if (this.selected)
                        this.apiList.selection.selectRow(this.selected.entity);
                });
            }


            gvwList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) 
                
                    this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiList.selection.selectRow(newCol.row.entity);
                        });
                    });

                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div> 
                                <button type="button"  ng-click="grid.appScope.goodsReceiptNoteListCtrl.viewGRNClick(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-eye"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 80,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "GRNNumber",
                    displayName: "GRN Number",
                    field: "GRNNumber",
                    width: 260,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "GRNDate",
                    displayName: "GRN Date",
                    field: "GRNDate",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="orderDate"></gts-date>
                                    
                        </div>`,
                },
                {
                    name: "IsFullGRN",
                    displayName: "Full GRN",
                    field: "IsFullGRN",
                    width: 120,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" />',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }
                ,
                {
                    name: "IsIntegrated",
                    displayName: "Integrated",
                    field: "IsIntegrated",
                    width: 120,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" />',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }
                ]
            };

            viewGRNClick(grnId:number)
            {
                this.grnId = grnId;
                this.addNew();
                
            }

            addNewClick(){
                this.grnId = undefined;
                this.addNew();
            }

            addNew() {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                        <div class="modal-header">
                            <h3 class="modal-title" id="modal-title">{{addNewCtrl.Title}}</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="goodsNote.add">
                            <form name="goodsNote">
                                <div class="gts-padding">
                                   <gts-message-display messages="Messages"></gts-message-display>
                                   <div class="modal-body" id="modal-body">
                                        <uib-tabset active="addNewCtrl.selectedTab">
                                            <uib-tab index="0" heading="GRN" select="addNewCtrl.tabChanged(0)" tabindex="0">
                                                  
                                                            <div class="row vertical-align">
                                                                <div class="col-md-4">
                                                                    <req></req>
                                                                    <label>Entity</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <gts-dropdown ng-disabled="addNewCtrl.grnId" name="ownerEntity" ng-model="addNewCtrl.goodsReceiptNote.Entity" form="inputForm" load-data="addNewCtrl.loadEntities(searchText)" required="true"></gts-dropdown>
                                                                </div>
                                                            </div>
                                        
                                                            <div class="row vertical-align">
                                                                <div class="col-md-4">
                                                                    <req></req>
                                                                    <label>GRN Number</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <p class="input-group-sm">
                                                                        <input ng-disabled="addNewCtrl.grnId" type="text" id="GRNNumber" class="form-control" ng-model="addNewCtrl.goodsReceiptNote.GRNNumber"></textarea>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div class="row vertical-align">
                                                                <div class="col-md-4">
                                                                    <req></req>
                                                                    <label>GRN Date</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                   
                                                                    <gts-date format="yyyy/MM/dd"
                                                                    ng-model="addNewCtrl.goodsReceiptNote.GRNDate"
                                                                    ng-disabled="addNewCtrl.grnId"
                                                                    name="GRNDate">
                                                                    </gts-date>
                                                                    
                                                                </div>
                                                            </div>     
                                                            
                                                            <div class="row vertical-align">
                                                                <div class="col-md-4">
                                                                    <opt></opt>
                                                                    <label>Is Full GRN</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <p class="input-group-sm">
                                                                        <input type="checkbox"    ng-disabled="addNewCtrl.grnId"
                                                                            ng-model="addNewCtrl.goodsReceiptNote.IsFullGRN" 
                                                                            name="IsFullGRN">
                                                                            <i class="fa fa-question-circle" uib-tooltip='If ticked, any previous GRNs will be overwritten and the received quantity entered will be recorded against the Purchase Order.' tooltip-placement="right"></i>
                                                                    </p>
                                                                    
                                                                </div>
                                                            </div>  

                                                            <div class="row vertical-align">
                                                            <div class="col-md-4">
                                                                <opt></opt>
                                                                <label>Close PO</label>
                                                            </div>
                                                            <div class="col-md-5">
                                                                <p class="input-group-sm">
                                                                    <input type="checkbox"    ng-disabled="addNewCtrl.grnId || !addNewCtrl.goodsReceiptNote.IsFullGRN"
                                                                        ng-model="addNewCtrl.goodsReceiptNote.ClosePO" 
                                                                        name="ClosePO">
                                                                        <i class="fa fa-question-circle" uib-tooltip='If ticked the Purchase Order(s) will be moved to a close status.' tooltip-placement="right"></i>
                                                                </p>
                                                                
                                                            </div>
                                                        </div>  
                                                            
                                                            <div class="row vertical-align">
                                                                <div class="col-md-4">
                                                                    <opt></opt>
                                                                    <label>Is Integrated</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <p class="input-group-sm">
                                                                        <input type="checkbox"  ng-disabled="true"
                                                                            ng-model="addNewCtrl.goodsReceiptNote.IsIntegrated" 
                                                                            name="IsIntegrated">
                                                                    </p>
                                                                </div>
                                                            </div> 
                                                            <div class="row vertical-align">
                                                                <div class="col-md-4">
                                                                    <opt></opt>
                                                                    <label>Comments</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <p class="input-group-sm">
                                                                        <textarea  ng-disabled="addNewCtrl.grnId" id="email" class="form-control" ng-model="addNewCtrl.goodsReceiptNote.Comments" rows="25"></textarea>
                                                                    </p>
                                                                </div>

                                                            </div>

                                                            

                                                                                                                                                                                 

                                            </uib-tab>
                                            <uib-tab disable="!addNewCtrl.goodsReceiptNote.Entity || !addNewCtrl.goodsReceiptNote.GRNNumber || !addNewCtrl.goodsReceiptNote.GRNDate" index="1" heading="Purchase Orders" select="addNewCtrl.tabChanged(1)"  tabindex="1">                                               
                                                    <div id="gvwList" ng-if="addNewCtrl.selectedTab === 1" ui-grid="addNewCtrl.gvwList" ui-grid-resize-columns ui-grid-auto-resize ui-grid-selection ui-grid-edit ui-grid-row-edit ui-grid-cellNav class="gridSmallScreen">
                                                                        
                                                    </div>
                                            </uib-tab>
                                            <uib-tab disable="addNewCtrl.selectedPOs.length === 0 && !addNewCtrl.grnId" index="2" heading="GRN Detail" select="addNewCtrl.tabChanged(2)" tabindex="2">
                                                <div id="gvwDISKUList" ng-if="addNewCtrl.selectedTab === 2" ui-grid="addNewCtrl.gvwDISKUList" ui-grid-edit ui-grid-auto-resize ui-grid-row-edit ui-grid-resize-columns ui-grid-selection ui-grid-cellNav class="gridSmallScreen" >
                                                
                                                </div>
                                            </uib-tab>                                        
                                        </uib-tabset>
                                   </div>                                  
                                    <div class="modal-footer">
                                    <gts-stamp-info  ng-if="addNewCtrl.goodsReceiptNote.Id>0" createstampfullname="addNewCtrl.goodsReceiptNote.CreateStampFullName" createstampdate="addNewCtrl.goodsReceiptNote.CreateStampDate"
                                    updatestampfullname="addNewCtrl.goodsReceiptNote.UpdateStampFullName" updatestampdate="addNewCtrl.goodsReceiptNote.UpdateStampDate">
                                    </gts-stamp-info>      

                                        <button  ng-if="!addNewCtrl.grnId"  ng-disabled="!addNewCtrl.goodsReceiptNote.Entity || !addNewCtrl.goodsReceiptNote.GRNNumber || !addNewCtrl.goodsReceiptNote.GRNDate || !addNewCtrl.apiDISKUList || !addNewCtrl.apiList" class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.goodsReceiptNote.Entity" ng-click="addNewCtrl.add()">{{addNewCtrl.saveMessage}}</button>
                                        <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">{{addNewCtrl.closeMessage}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class addNewCtrl {
                        selectedEntity: interfaces.applicationcore.IDropdownModel;

                        goodsReceiptNote: interfaces.master.IGoodsReceiptNote;

                        messages: interfaces.applicationcore.IMessage[];

                        saveMessage: string = "Add New";
                        Title: string = "Goods Receipt Note - Create";
                        closeMessage: string = "Cancel";

                        EnableNextButton:boolean = false;

                        selectedTab:number = 0;

                        IsLoading:boolean=false;

                        apiList: uiGrid.IGridApi;

                        apiDISKUList: uiGrid.IGridApi;

                        DILineList: interfaces.master.IDILine[] = [];

                        GRNDetailList: interfaces.master.IGRNDetail[] = [];


                        selectedPO: uiGrid.IGridRow;
                        selectedPOs: interfaces.master.IGRNDeliveryInstructionViewModel[] = [];
                        $scope: any;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private goodsReceiptNoteService: interfaces.master.IGoodsReceiptNoteService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            private $timeout: ng.ITimeoutService,
                            private bsLoadingOverlayService,
                            private grnId : number
                        ) {

                            this.$scope = $scope;

                            this.selectedEntity = <interfaces.applicationcore.IDropdownModel>{
                                Id: $rootScope.Entity.Id,
                                Code: $rootScope.Entity.Code,
                                Description: $rootScope.Entity.Name,
                                Display: $rootScope.Entity.Code + " - " + $rootScope.Entity.Name,
                                Selected: true
                            };

                            
                            if (grnId)
                            {
                                    this.loadGoodsReceiptNote();
                                  
                                    this.saveMessage = "Save";
                                    this.Title = "Goods Receipt Note - View"
                                    this.closeMessage = "Close";
                            }
                            else
                            {
                                this.goodsReceiptNote = <interfaces.master.IGoodsReceiptNote>{
                                    Id: 0,
                                    Entity: this.selectedEntity,
                                    GRNDate:  moment.utc()                      
                                }

                                this.saveMessage = "Add New";
                                this.closeMessage = "Cancel";
                            }
                        }

                        tabChanged(tabIndex:number)
                        {
                            if (tabIndex === 1)
                            {
                                this.loadDeliveryInstructionViewModelList();
                            }

                            if (tabIndex === 2)
                            {
                                this.loadDISKUAllocationList();
                            }
                        }

                        loadGoodsReceiptNote() {
                            return this.goodsReceiptNoteService.getGoodsReceiptNoteDetail().get({
                                grnId: this.grnId,
                            }, (result: interfaces.master.IGoodsReceiptNote) => {
                                this.goodsReceiptNote = result;    
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                        }

                        loadDISKUAllocationList(){
                            this.IsLoading = true;
                            this.bsLoadingOverlayService.wrap({
                            },() => {

                                this.DILineList = [];
                                
                                if (this.apiList){
                                    this.DILineList = _.map(this.apiList.selection.getSelectedRows(), (o : interfaces.master.IGRNDeliveryInstructionViewModel) => {
                                        return {
                                            pdiId: o.pdiId
                                        };
                                    });
                                }

                                var params = {
                                    grnId: this.goodsReceiptNote.Id
                                }
            
                                this.goodsReceiptNoteService.getDISKUAllocationViewModelList(                                        
                                    ).query(params,this.DILineList,(data : interfaces.purchase.IDeliveryInstructionSKUAllocation[]) => {
                                        this.gvwDISKUList.data = data;
                                        this.IsLoading = false;
                                    },
                                    (errorResponse) => {
            
                                        this.generalService.displayMessageHandler(errorResponse.data);
                                        this.IsLoading = false;
                                    });
                            });
                        }

                        loadDeliveryInstructionViewModelList() { 
                            this.IsLoading = true;
                            this.bsLoadingOverlayService.wrap({
                            },() => {
                                var params = {
                                    grnId: this.goodsReceiptNote.Id,
                                    entityId: this.goodsReceiptNote.Entity.Id
                                }
            
                               // this.$timeout(() => {
                                    this.goodsReceiptNoteService.getGRNDeliveryInstructionViewModelList(                                        
                                    ).query(params,(data : interfaces.master.IGRNDeliveryInstructionViewModel[]) => {
                                        //this.POList = data;
                                      
                                        this.gvwList.data = data;

                                        this.$timeout(() => {
                                            if(this.selectedPOs)
                                            {
                                                _.forEach(this.selectedPOs, (po) => {
                                                    this.apiList.selection.unSelectRow(po);
                                                    this.apiList.selection.selectRow(po);
                                                })
                                            }
                                        });
                                                                               
                                        this.IsLoading = false;
                                    },
                                    (errorResponse) => {
            
                                        this.generalService.displayMessageHandler(errorResponse.data);
                                        this.IsLoading = false;
                                    });
                              //  });
                            });
                        }
            



                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        add() {
                            var saveItem =  this.goodsReceiptNote;

                            var params = {
                                entityId: this.goodsReceiptNote.Entity.Id,
                                GRNNumber: this.goodsReceiptNote.GRNNumber,
                                GRNDate: this.goodsReceiptNote.GRNDate,
                                IsFullGRN: this.goodsReceiptNote.IsFullGRN,
                                Comments: this.goodsReceiptNote.Comments,
                                ClosePO: this.goodsReceiptNote.ClosePO
                            }

                            
                            this.GRNDetailList = [];

                          var dirtyRows = this.apiDISKUList.rowEdit.getDirtyRows(this.apiDISKUList.grid);

                         
                            this.GRNDetailList = _.map(this.apiList.selection.getSelectedRows(), (o : interfaces.purchase.IDeliveryInstructionSKUAllocation) => {
                                return { pdsId:o.Id, grnQuantity:o.ReceivedQuantity};
                            });

                            var selectedPurchaseOrderLines: interfaces.master.IGRNDetail[] = [];
                            selectedPurchaseOrderLines = _.map(this.apiDISKUList.rowEdit.getDirtyRows(this.apiDISKUList.grid), (o) => {
                                return { pdsId:o.entity.Id, grnQuantity:o.entity.ReceivedQuantity};
                            });
            
                            this.GRNDetailList = selectedPurchaseOrderLines.filter(n => n);

                            var ordLineList = _.map(this.apiList.selection.getSelectedRows(), (o : interfaces.master.IGRNDeliveryInstructionViewModel) => {
                                return {
                                    ordId: o.ordId
                                };
                            });

                            var grnDTO = <interfaces.master.GRNDTO>{
                                goodsReceiptNote:this.goodsReceiptNote,
                                GRNDetailList : this.GRNDetailList,
                                OrderLineList : ordLineList
                            }

                            if (ordLineList.length === 0)
                            {
                                this.generalService.displayMessage("No Purchase Orders selected to GRN", Enum.EnumMessageType.Error);
                                return;
                            }

                            if (this.GRNDetailList.length === 0)
                            {
                                this.generalService.displayMessage("No SKU lines have been updated with GRN Quantity", Enum.EnumMessageType.Error);
                                return;
                            }

                            this.goodsReceiptNoteService.saveGoodsReceiptNote().save({},grnDTO, (result) => {
                                this.generalService.displayMessageHandler(result);
                                if (!result.HasErrorMessage) {
                                    this.$uibModalInstance.close(true);
                                }

                            }, (errorResult) => {
                                this.generalService.displayMessageHandler(errorResult.data);

                                if (errorResult.data.Messages) {
                                    this.messages = [];
                                }

                                angular.forEach(errorResult.data.Messages, (o) => {
                                    angular.forEach(o.MessageItems, (n) => {
                                        this.messages.push(n);
                                    });
                                });
                            });
                        }

                        loadEntities(searchText: string) {
                            return this.entityService.getDropdownList(searchText).query(
                                () => {
                                }, (failureData) => {
                                }).$promise;
                        }


                        registerGridApiDISKU(gridApi: uiGrid.IGridApi){
                            this.apiDISKUList = gridApi;
                        }

                        registerGlistAPI(gridApi: uiGrid.IGridApi){
                            this.apiList = gridApi;

                            

                             this.apiList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                                
                                this.selectedPO = selected;

                                var foundItem = _.find(this.selectedPOs, selected.entity);

                                if(!foundItem) {
                                    if (selected.isSelected) 
                                        this.selectedPOs = _.concat(this.selectedPOs, selected.entity);
                                } else {
                                    if (!selected.isSelected) 
                                        _.remove(this.selectedPOs, selected.entity);
                                }
                                
                                if (this.apiList.selection.getSelectedRows().length > 0) {
                                    this.EnableNextButton = true;
                                } else 
                                    this.EnableNextButton = false;
                            });

                            this.$timeout(() => {
                                if(this.selectedPOs)
                                {
                                    _.forEach(this.selectedPOs, (po) => {
                                        this.apiList.selection.unSelectRow(po);
                                        this.apiList.selection.selectRow(po);
                                    })
                                }
                            });
                        }

            

                        gvwList: uiGrid.IGridOptions = {
                            data: [],
                            enableFiltering: true,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: true,
                            enableColumnResizing: true,
                            enableRowSelection: true,
                            enableFullRowSelection: true,
                            paginationPageSizes: [25, 50, 75, 100],
                            paginationPageSize: 25,
                            useExternalPagination: false,
                            enableRowHeaderSelection: true,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            rowEditWaitInterval: -1,
                            onRegisterApi: (gridApi) => { this.registerGlistAPI(gridApi) },
                            columnDefs: [{
                                name: "ID",
                                displayName: "ID",
                                field: "Id",
                                visible: false,
                                enableFiltering: false
                        }, {
                                name: "PurchaseOrderNumber",
                                displayName: "PO Number",
                                field: "OrderNo",
                                width: 160,
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                        }, {
                            name: "DeliveryInstructionNo",
                            displayName: "Delivery Instruction No",
                            field: "DeliveryInstructionNo",
                            width: 160,
                            filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`
                            },
                            {
                                name: "Status",
                                displayName: "Order Status",
                                field: "Status",
                                width: 160,
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                                }                                
                            ]
                        };

                        gvwDISKUList: uiGrid.IGridOptions = {
                            data: [],
                            enableFiltering: true,
                            useExternalFiltering: false,
                            enableCellEdit: true,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableRowSelection: false,
                            enableFullRowSelection: false,
                            paginationPageSizes: [25, 50, 75, 100],
                            paginationPageSize: 25,
                            useExternalPagination: false,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            rowEditWaitInterval: -1,
                            onRegisterApi: (gridApi) => {
                                this.registerGridApiDISKU(gridApi);                                                                                  
                            },
                            columnDefs: [{
                                name: "ID",
                                displayName: "ID",
                                field: "Id",
                                visible: false,
                                enableFiltering: false
                            },
                            {
                                name: "LineSequenceNumber",
                                displayName: "#",
                                field: "POSequenceNumber",
                                width: 32,                 
                                cellClass: 'text-right',
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                            },
                            {
                                name: "SkuCode",
                                displayName: "Sku Code",
                                field: "SKUCode",
                                width: 120,                    
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },
                            {
                                name: "SkuDescription",
                                displayName: "Description",
                                field: "SKUDescription",
                                width: 280,                    
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },
                            {
                                name: "POPrice",
                                displayName: "PO Unit Price",
                                field: "SupplierPrice",
                                width: 120,
                                cellClass: 'text-right',
                                cellFilter: "number: 5",
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`
                            },
                            {
                                name: "CurrencyCode",
                                displayName: "Currency",
                                field: "CurrencyCode",
                                width: 150,
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`
                            },                      
                            {
                                name: "OrderedQuantity",
                                displayName: "Ordered Quantity",
                                field: "OrderedQuantity",
                                width: 180,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },                           
                            {
                                name: "AvailableQuantity",
                                displayName: "Available Quantity",
                                field: "AvailableQuantity",
                                width: 180,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },                      
                            {
                                name: "AllocatedQuantity",
                                displayName: "Allocated Quantity",
                                field: "AllocatedQuantity",
                                width: 180,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },                    
                            {
                                name: "InvoiceQuantity",
                                displayName: "Invoiced Quantity",
                                field: "InvoiceQuantity",
                                width: 180,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },                                                        
                            {
                                name: "GRNQaty",
                                displayName: "GRN Qty",
                                field: "ReceivedQuantity",
                                width: 150,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                enableCellEditOnFocus: true,
                                enableCellEdit:  !this.grnId,
                                editableCellTemplate: `
                                            <form name="inputForm">
                                                <p class="input-group-sm">
                                                    <input type="number" ignore-wheel name="grossmass-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.000001" />
                                                </p>
                                            </form>`
                            }]
                        };

               

                    },
                    controllerAs: "addNewCtrl",
                    resolve: { grnId : this.grnId
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadgoodsReceiptNotes();
                    }
                });
            }


        }

        angular.module("app").controller("goodsReceiptNoteListCtrl", controllers.master.goodsReceiptNoteListCtrl);
    }
}